import axios from '@/plugins/axios';

export default {
  getUserLoginsCount(config = {}) {
    return axios.get('/v1/master/dashboard/user-logins-count', config);
  },
  getOrganisationsCount(config = {}) {
    return axios.get('/v1/master/dashboard/organisations-count', config);
  },
  getAssetsCount(config = {}) {
    return axios.get('/v1/master/dashboard/assets-count', config);
  },
  getAssetReportsCount(config = {}) {
    return axios.get('/v1/master/dashboard/asset-reports-count', config);
  },
};
