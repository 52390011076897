<template>
  <dashboard-box icon="user" :heading="$t('user_logins')" :count="count" :loading="count === null">
    <template v-slot:filters>
      <form-open class="grid xl:grid-cols-2 gap-4">
        <form-select
          :form-id="formId"
          :label="$t('organisation')"
          :options="data.organisations"
          :placeholder="$t('select_placeholder_all')"
          :required="false"
          :show-validation-label="false"
          @input="update"
          class="col-span-full"
          input-id="organisation"
          v-model="query.filter.organisation"
        />
        <form-text
          :form-id="formId"
          :label="$t('date_from')"
          :required="false"
          :show-validation-label="false"
          @input="update"
          input-id="date-from"
          type="date"
          v-model="query.filter.date_from"
        />
        <form-text
          :form-id="formId"
          :label="$t('date_to')"
          :required="false"
          :show-validation-label="false"
          @input="update"
          input-id="date-to"
          type="date"
          v-model="query.filter.date_to"
        />
      </form-open>
    </template>
  </dashboard-box>
</template>

<script>
import ApiMasterDashboardService from '@/services/api/master/dashboard';
import ApiMasterOrganisationService from '@/services/api/master/organisation';
import DashboardBox from '@/components/DashboardBox.vue';
import HasForms from '@/mixins/hasForms';

export default {
  components: {
    DashboardBox,
  },
  data() {
    return {
      count: null,
      data: {
        organisations: [],
      },
      formId: 'dashboardMasterUserLogins',
      query: {
        filter: {},
      },
    };
  },
  methods: {
    update() {
      ApiMasterDashboardService.getUserLoginsCount({
        params: this.query,
      }).then((response) => {
        const { data } = response.data;

        this.count = data.count;
      }).catch(() => {});
    },
  },
  mixins: [
    HasForms,
  ],
  mounted() {
    ApiMasterOrganisationService.getOrganisations({
      params: {
        sort: [
          'name',
        ],
        per_page: -1,
      },
    }).then((response) => {
      const { data } = response.data;

      this.data.organisations = this.toSelectOptions(data);
    }).catch(() => {});

    this.update();
  },
};
</script>
