<template>
  <dashboard-box icon="user-group" :heading="$t('users')" :count="count" :loading="count === null">
    <template v-slot:filters>
      <form-open class="grid xl:grid-cols-2 gap-4" @submit="submit">
        <form-select
          :label="$t('status')"
          :multiple="true"
          :options="data.statuses"
          :placeholder="$t('select_placeholder_all')"
          :required="false"
          :show-validation-label="false"
          @input="submit"
          class="col-span-full"
          input-id="status"
          v-model="query.filter.status"
        />
        <button class="hidden"/>

        <div class="col-span-full flex justify-between">
          <a href="#" class="text-sm text-app-orange font-semibold link" v-show="false">
            {{ $t('create') }}
          </a>
          <a href="#" class="text-sm font-semibold link" @click.prevent="view">
            {{ $t('view') }}
          </a>
        </div>
      </form-open>
    </template>
  </dashboard-box>
</template>

<script>
import ApiUserService from '@/services/api/user';
import DashboardBox from '@/components/DashboardBox.vue';
import HasForms from '@/mixins/hasForms';
import ApiUserStatusService from '@/services/api/user_status';

export default {
  components: {
    DashboardBox,
  },
  data() {
    return {
      count: null,
      data: {
        statuses: [],
      },
      query: {
        filter: {},
      },
    };
  },
  methods: {
    submit() {
      ApiUserService.getOrganisationUsers({
        params: this.query,
      }).then((response) => {
        const { meta } = response.data;

        this.count = meta.total;
      }).catch(() => {});
    },
    view() {
      this.$router.push({
        name: 'users',
        query: this.query,
      });
    },
  },
  mixins: [
    HasForms,
  ],
  mounted() {
    ApiUserStatusService.getStatuses().then((response) => {
      const { data } = response.data;

      this.data.statuses = this.toSelectOptions(data, 'status', 'id', (label) => `user_status.${label}`);
    }).catch();

    this.submit();
  },
};
</script>
