<template>
  <dashboard-box icon="document-report" :heading="$t('asset_reports')" :count="assetReportsCount" :loading="assetReportsCount === null">
    <template v-slot:filters>
      <form-open class="grid xl:grid-cols-2 gap-4" @submit="submit">
        <form-select
          :label="$t('presets')"
          :options="data.presets"
          :required="false"
          :show-validation-label="false"
          class="col-span-full"
          input-id="filters"
          v-model="preset"
        />
        <form-select
          :label="$t('action_req')"
          :options="data.yes_no"
          :placeholder="$t('select_placeholder_all')"
          :show-validation-label="false"
          :required="false"
          @input="submit"
          class="col-span-full"
          input-id="action-req"
          v-model="query.filter.action_required"
          v-show="false"
        />
        <button class="hidden"/>

        <div class="col-span-full flex justify-between">
          <a href="#" class="text-sm font-semibold link" v-show="false">
            {{ $t('create') }}
          </a>
          <a href="#" class="text-sm font-semibold link" @click.prevent="view">
            {{ $t('view') }}
          </a>
        </div>
      </form-open>
    </template>
  </dashboard-box>
</template>

<script>
import ApiAssetReportService from '@/services/api/asset_report';
import DashboardBox from '@/components/DashboardBox.vue';
import HasForms from '@/mixins/hasForms';
import moment from 'moment';

export default {
  components: {
    DashboardBox,
  },
  computed: {
    presetFilters() {
      const dateNowFormatted = moment().format('YYYY-MM-DD');

      return {
        month: {
          filter: {
            date_from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            date_to: dateNowFormatted,
          },
        },
        week: {
          filter: {
            date_from: moment().subtract(1, 'week').format('YYYY-MM-DD'),
            date_to: dateNowFormatted,
          },
        },
        year: {
          filter: {
            date_from: moment().subtract(1, 'year').format('YYYY-MM-DD'),
            date_to: dateNowFormatted,
          },
        },
      };
    },
  },
  data() {
    return {
      assetReportsCount: null,
      data: {
        presets: [
          {
            value: 'month',
            label: this.$t('month'),
          },
          {
            value: 'week',
            label: this.$t('week'),
          },
          {
            value: 'year',
            label: this.$t('year'),
          },
        ],
        yes_no: [
          {
            value: '1',
            label: this.$t('yes'),
          },
          {
            value: '0',
            label: this.$t('no'),
          },
        ],
      },
      preset: null,
      query: this.emptyQuery(),
    };
  },
  methods: {
    emptyQuery() {
      return {
        filter: {},
      };
    },
    submit() {
      ApiAssetReportService.getOrganisationAssetReports({
        params: this.query,
      })
        .then((response) => {
          const { meta } = response.data;

          this.assetReportsCount = meta.total;
        })
        .catch(() => {});
    },
    view() {
      this.$router.push({
        name: 'asset_reports',
        query: this.query,
      });
    },
  },
  mixins: [
    HasForms,
  ],
  mounted() {
    this.submit();
  },
  watch: {
    preset: {
      handler(preset) {
        if (this.presetFilters[preset]) {
          this.query = this.presetFilters[preset];
        } else {
          this.query = this.emptyQuery();
        }

        this.submit();
      },
    },
  },
};
</script>
