<template>
  <div class="border-4 border-dashed border-gray-200 p-4 min-h-full">
    <div class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-4">
      <dashboard-master-user-logins v-if="$can('master')"/>
      <dashboard-master-organisations v-if="$can('master')"/>
      <dashboard-master-assets v-if="$can('master')"/>
      <dashboard-master-asset-reports v-if="$can('master')"/>
      <dashboard-assets v-if="$can('manage_assets')"/>
      <dashboard-users v-if="$can('manage_users')"/>
      <dashboard-asset-reports v-if="$can('manage_asset_reports')"/>
    </div>
  </div>
</template>

<script>
import DashboardAssetReports from '@/components/Dashboard/AssetReports.vue';
import DashboardAssets from '@/components/Dashboard/Assets.vue';
import DashboardMasterAssetReports from '@/components/Dashboard/Master/AssetReports.vue';
import DashboardMasterAssets from '@/components/Dashboard/Master/Assets.vue';
import DashboardMasterOrganisations from '@/components/Dashboard/Master/Organisations.vue';
import DashboardMasterUserLogins from '@/components/Dashboard/Master/UserLogins.vue';
import DashboardUsers from '@/components/Dashboard/Users.vue';

export default {
  components: {
    DashboardAssetReports,
    DashboardAssets,
    DashboardMasterAssetReports,
    DashboardMasterAssets,
    DashboardMasterOrganisations,
    DashboardMasterUserLogins,
    DashboardUsers,
  },
  metaInfo() {
    return {
      title: this.$t('dashboard'),
    };
  },
};
</script>
