<template>
  <div class="bg-white shadow flex flex-col"
    :class="{
      'opacity-20': loading,
    }"
  >
    <div class="p-6">
      <div class="flex items-center">
        <app-svg class="h-8 w-8 text-gray-400" :svg="icon"/>
        <div class="ml-4">
          <dt class="text-sm font-medium text-gray-500 truncate">{{ heading }}</dt>
          <dd class="text-3xl font-semibold text-gray-900">{{ countAnimated }}</dd>
        </div>
      </div>
    </div>

    <div class="p-6 bg-gray-50 flex-grow" v-if="$slots.filters">
      <slot name="filters"/>
    </div>
  </div>
</template>

<script>
import AppSvg from '@/components/AppSvg.vue';
import { gsap } from 'gsap';

export default {
  components: {
    AppSvg,
  },
  computed: {
    countAnimated() {
      return this.countTweened.toFixed(0);
    },
  },
  data() {
    return {
      countTweened: 0,
    };
  },
  methods: {
    tween(from, to) {
      // https://vuejs.org/v2/guide/transitioning-state.html#Animating-State-with-Watchers
      gsap.to(this.$data, { duration: 0.5, countTweened: to });
    },
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    count: {
      type: [
        Number,
      ],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    count(newValue, oldValue) {
      this.tween(oldValue ?? 0, newValue);
    },
  },
};
</script>
