<template>
  <dashboard-box icon="collection" :heading="$t('assets')" :count="count" :loading="count === null">
    <template v-slot:filters>
      <form-open class="grid xl:grid-cols-2 gap-4" @submit="submit">
        <form-select
          :label="$t('categories')"
          :options="data.categories"
          :placeholder="$t('select_placeholder_all')"
          :show-validation-label="false"
          :required="false"
          @input="submit"
          class="col-span-full"
          input-id="categories"
          v-model="query.filter.category"
        />
        <button class="hidden"/>

        <div class="col-span-full flex justify-between">
          <a href="#" class="text-sm text-app-orange font-semibold link" v-show="false">
            {{ $t('create') }}
          </a>
          <a href="#" class="text-sm font-semibold link" @click.prevent="view">
            {{ $t('view') }}
          </a>
        </div>
      </form-open>
    </template>
  </dashboard-box>
</template>

<script>
import ApiAssetCategoryService from '@/services/api/asset_category';
import DashboardBox from '@/components/DashboardBox.vue';
import HasForms from '@/mixins/hasForms';
import ApiAssetService from '@/services/api/asset';

export default {
  components: {
    DashboardBox,
  },
  data() {
    return {
      count: null,
      data: {
        categories: [],
      },
      query: {
        filter: {},
      },
    };
  },
  methods: {
    submit() {
      ApiAssetService.getOrganisationAssets({
        params: this.query,
      })
        .then((response) => {
          const { meta } = response.data;

          this.count = meta.total;
        })
        .catch(() => {});
    },
    view() {
      this.$router.push({
        name: 'assets',
        query: this.query,
      });
    },
  },
  mixins: [
    HasForms,
  ],
  mounted() {
    ApiAssetCategoryService.getAssetCategories({
      params: {
        per_page: -1,
        sort: 'name',
      },
    })
      .then((response) => {
        const { data } = response.data;

        this.data.categories = this.toSelectOptions(data);
      })
      .catch(() => {});

    this.submit();
  },
};
</script>
